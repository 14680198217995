import axios from 'axios'
import { dfs } from '@/helpers'

let pollingInterval = null
export default {
  namespaced: true,
  state: {
    categories: [],
    products: []
  },
  getters: {
    getCategoryById: (state) => (id) => {
      let category = null
      dfs(state.categories, (c) => {
        if (c.id === id) {
          category = c
        }
      })
      return category
    },
    getProductById: (state) => (id) => {
      for (const product of state.products) {
        if (product.id === id) {
          return product
        }
      }
      return null
    }
  },
  mutations: {
    SET_CATEGORIES (state, categories) {
      state.categories = categories
    },
    SET_PRODUCTS (state, products) {
      state.products = products
    },
    UPDATE_STOCKS (state, stocks) {
      state.products.forEach(function (product) {
        if (product.id in stocks) {
          product.stock = stocks[product.id]
        }
      })
    }
  },
  actions: {
    async fetchCategories ({ commit }) {
      return axios.get('/api/categories').then((response) => {
        const categories = response.data.data
        commit('SET_CATEGORIES', categories)
      }).catch((e) => {
        console.error(e)
        console.error('Unable to retrieve categories')
      })
    },
    async fetchProducts ({ commit }) {
      return axios.get('/api/products').then((response) => {
        commit('SET_PRODUCTS', response.data.data)
      }).catch((e) => {
        console.error(e)
        console.error('Unable to retrieve products')
      })
    },
    clear ({ commit }) {
      commit('SET_CATEGORIES', [])
      commit('SET_PRODUCTS', [])
    },
    initPolling ({ state, dispatch, commit }) {
      const delay = 180000 // 3 minutes

      if (pollingInterval === null) {
        dispatch('pollCatalog')
        pollingInterval = setInterval(() => {
          dispatch('pollCatalog')
        }, delay)
      }
    },
    async pollCatalog ({ dispatch }) {
      dispatch('fetchCategories')
      dispatch('fetchProducts')
    }
  }
}
