<template>
  <div class="product">
    <div>
      <img
        v-lazy="product?.images?.zoom?.url || ''"
        class="product__picture is-clickable"
        :alt="product?.images?.thumbnail?.alt || ''"
        @click="openModal(product?.images?.zoom?.url || '')"
      >
      <div class="product__name">
        {{ product.name }}
      </div>
    </div>
    <div class="product__moreWrapper">
      <div class="product__quantity">
        <input
          v-model="quantity"
          type="number"
          :step="product.packaging"
          min="0"
          @change="updateQuantity"
        >
        <div>
          {{ unitPrice }}
        </div>
      </div>
      <div class="mb1">
        En stock : {{ product.stock }}
      </div>
      <button
        class="product__more"
        @click="showInfos(product)"
      >
        Plus de détails
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import debounce from 'debounce'
import tingle from 'tingle.js'

// eslint-disable-next-line
const modalPreview = new tingle.modal({
  cssClass: ['modal-preview']
})
// eslint-disable-next-line
const modalInfos = new tingle.modal({
  cssClass: ['modal', 'modal--pa']
})

export default {
  name: 'Card',
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    topCategory: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      quantity: null
    }
  },
  computed: {
    ...mapGetters('cart', [
      'productQuantities'
    ]),
    cartQuantity () {
      return this.productQuantities[this.product.id + '-' + this.topCategory.id] || null
    },
    unitPrice () {
      if (this.topCategory.is_pre_order) {
        return this.product.pre_order_price === null ? 'Définis lors de la facturation' : this.product.pre_order_price
      }

      return this.product.prices[0].price || ''
    },
    hasSpecialRate () {
      return this.product.prices.length > 1 && !(this.topCategory.is_pre_order && this.product.pre_order_price === null)
    }
  },
  created () {
    this.quantity = this.cartQuantity
  },
  methods: {
    ...mapActions({ addProduct: 'cart/addProduct' }),
    updateQuantity: debounce(function () {
      this.quantity = Math.ceil(this.quantity / this.product.packaging) * this.product.packaging
      this.addProduct([this.product.id, this.quantity, this.topCategory.id])
        .then(() => {
          this.quantity = this.cartQuantity
        })
        .catch((error) => {
          this.$notyf.open({
            type: error.name === 'ValidationError' ? 'warning' : 'error',
            message: error.message
          })

          this.quantity = this.cartQuantity
        })
    }, 500),
    openModal (pictureURL) {
      modalPreview.setContent('<img src="' + pictureURL + '">')
      modalPreview.open()
    },
    showInfos (product) {
      let quantity = ''
      product.prices.forEach(el => {
        quantity += `<div><div class="quantity__label">${el.label}</div><div>${el.price}</div></div>`
      })
      modalInfos.setContent(`
        <div class="modal__title">${product.name}</div>
        <div class="modal__layout">
          <div>
            <img src="${product.images.zoom.url}" class="modal__picture">
          </div>
          <div class="ml3">
            <div>
              <div>
                <div class="important">Gencode</div>
                <div>${product.gencode}</div>
                <div class="important mt1">Tarif / quantité</div>
                <div class="quantity">${quantity}</div>
              </div>
            </div>
            <div class="important mt1">Stock restant</div>
            <div>${product.stock}</div>
          </div>
        </div>
      `)
      modalInfos.open()
    },
    toto () {
      alert('plop')
    }
  }
}
</script>
